import React, { forwardRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import IconExport from "@mui/icons-material/FileDownload";
import logUrl from '../../assets/insurality.png'

const ExportButton = forwardRef((props, ref) => {

  const handleExportClick = async () => {
    const element = document.getElementById("export");
    if (element) {
      try {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png");
  
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
        // Add logo at the top-left corner
        const logoURL = logUrl; // Use the imported logo URL
        const logoWidth = 25; // Adjust logo width
        const logoHeight =5; // Adjust logo height
        const logoX = 5; // X-coordinate for logo
        const logoY = 10; // Y-coordinate for logo
  
        pdf.addImage(logoURL, "PNG", logoX, logoY, logoWidth, logoHeight);
  
        // Original heading with letter spacing
        const addLetterSpacing = (text, spacing = 1) =>
          text.split("").join(" ".repeat(spacing));
        const heading = addLetterSpacing("LIFETIME REPORT", 1.1); // Adjust spacing
  
        pdf.setTextColor(36, 82, 123);
        pdf.setFontSize(18);
        pdf.setFont("Arial", "fangsong");
  
        // Align heading on the same line as the logo
        const textY = logoY + logoHeight / 2 + 2; // Center-align text vertically with the logo
        const textX = logoX + logoWidth + 30; // Add horizontal spacing after the logo
  
        pdf.text(heading, textX, textY); // Draw the heading next to the logo
  
        const xOffset = 10;
        const imageYPosition = logoY + logoHeight + 20; // Position content below the logo and heading
  
        pdf.addImage(
          image,
          "PNG",
          xOffset,
          imageYPosition,
          pdfWidth - xOffset * 2,
          pdfHeight
        );
  
        pdf.save("Insurality.pdf");
      } catch (error) {
        console.error("An error occurred while exporting the PDF:", error);
      }
    } else {
      console.error("Element with id 'export' not found");
    }
  };
  

  useImperativeHandle(ref, () => ({
    export: handleExportClick,
  }));

  return (
    <button className="" onClick={handleExportClick} style={props.style}>
      <IconExport />
    </button>
  );
});

export default ExportButton;
