import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import "./FormDesign.css"; // Ensure the path is correct
import Header from "../HeadingList/HeadingList"; // Adjust the path as needed

const AssetsIncome = ({ onDataChange, initialData }) => {
  const [data, setData] = useState({
    activeIncome: [
      { name: "", monthly: "", annual: "", startYear: "", endYear: "", incomeReplacement: false },
    ],
    // passiveIncome: [{ monthly: "", annual: "", incomeReplacement: false }],
    totalHouseholdIncome: { monthly: "", annual: "", incomeReplacement: false },
    homeEquity: [{ home: "", currentValue: "", mortageProtection: false }],
    commute: [
      { name: "", oneWay: "", annual: "", accidentalDeathBenefit: false },
    ],
    savings: [
      { bank: "", monthly: "", balance: "", speciallyDesignedWholeLife: false },
    ],
    fourZeroOneK: [
      { name: "", monthly: "", balance: "", personalPensionPlan: false },
    ],
  });

  useEffect(() => {
    if (initialData) {
      setData({
        activeIncome: initialData?.activeIncome.map((income) => ({
          name: income.name || "",
          // monthly: income.monthly || "",
          startYear: income.startYear ? income.startYear : "",
          endYear: income.endYear ? income.endYear : "",
          annual: income.annual || "",
          incomeReplacement: income.incomeReplacement === true,
        })),
        // passiveIncome: initialData.passiveIncome.map((income) => ({
        //   name: income.name,
        //   monthly: income.monthly || "",
        //   annual: income.annual || "",
        //   incomeReplacement: income.incomeReplacement === true,
        // })),
        totalHouseholdIncome: {
          monthly: initialData.totalHouseholdIncome.monthly || "",
          annual: initialData.totalHouseholdIncome.annual || "",
          incomeReplacement:
            initialData.totalHouseholdIncome.incomeReplacement === true,
        },
        homeEquity: initialData.homeEquity.map((equity) => ({
          home: equity.home || "",
          currentValue: equity.currentValue || "",
          mortageProtection: equity.mortageProtection === true,
        })),
        commute: initialData.commute.map((commute) => ({
          name: commute.name || "",
          oneWay: commute.oneWay || "",
          annual: commute.annual || "",
          accidentalDeathBenefit: commute.accidentalDeathBenefit === true,
        })),
        savings: initialData.savings.map((saving) => ({
          bank: saving.bank || "",
          balance: saving.balance || "",
          monthly: saving.monthly || "",
          speciallyDesignedWholeLife:
            saving.speciallyDesignedWholeLife === true,
        })),
        fourZeroOneK: initialData.fourZeroOneK.map((fourZeroOneK) => ({
          name: fourZeroOneK.name || "",
          monthly: fourZeroOneK.monthly || "",
          balance: fourZeroOneK.balance || "",
          personalPensionPlan: fourZeroOneK.personalPensionPlan === true,
        })),
      });
    }
  }, [initialData]);

  useEffect(() => {
    const payload = {
      activeIncome: data.activeIncome.map((income) => ({
        name: income.name,
        // monthly: income.monthly ? parseFloat(income.monthly) : "",
        annual: income.annual ? parseFloat(income.annual) : "",
        startYear: income.startYear ? income.startYear : "",
        endYear: income.endYear ? income.endYear : "",
        incomeReplacement: income.incomeReplacement ? true : false,
      })),
      // passiveIncome: data.passiveIncome.map((income) => ({
      //   name: income.name,
      //   monthly: income.monthly ? parseFloat(income.monthly) : "",
      //   annual: income.annual ? parseFloat(income.annual) : "",
      //   incomeReplacement: income.incomeReplacement ? true : false,
      // })),
      totalHouseholdIncome: {
        monthly: data.totalHouseholdIncome.monthly
          ? parseFloat(data.totalHouseholdIncome.monthly)
          : "",
        annual: data.totalHouseholdIncome.annual
          ? parseFloat(data.totalHouseholdIncome.annual)
          : "",
        incomeReplacement: data.totalHouseholdIncome.incomeReplacement
          ? true
          : false,
      },
      homeEquity: data.homeEquity.map((equity) => ({
        home: equity.home,
        currentValue: equity.currentValue
          ? parseFloat(equity.currentValue)
          : "",
        mortageProtection: equity.mortageProtection ? true : false,
      })),
      commute: data.commute.map((commute) => ({
        name: commute.name,
        oneWay: commute.oneWay ? parseFloat(commute.oneWay) : "",
        annual: commute.annual ? parseFloat(commute.annual) : "",
        accidentalDeathBenefit: commute.accidentalDeathBenefit ? true : false,
      })),
      savings: data.savings.map((saving) => ({
        bank: saving.bank,
        monthly: saving.monthly ? parseFloat(saving.monthly) : "",
        balance: saving.balance ? parseFloat(saving.balance) : "",
        speciallyDesignedWholeLife: saving.speciallyDesignedWholeLife
          ? true
          : false,
      })),
      fourZeroOneK: data.fourZeroOneK.map((fourZeroOneK) => ({
        name: fourZeroOneK.name,
        monthly: fourZeroOneK.monthly ? parseFloat(fourZeroOneK.monthly) : "",
        balance: fourZeroOneK.balance ? parseFloat(fourZeroOneK.balance) : "",
        personalPensionPlan: fourZeroOneK.personalPensionPlan ? true : false,
      })),
    };

    onDataChange(payload);
  }, [data, onDataChange]);

  const handleChange = (e, index, category, name) => {
    const { value, type, checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [category]: Array.isArray(prevData[category])
        ? prevData[category].map((item, i) =>
          i === index
            ? {
              ...item,
              [name]: type === "checkbox"
                ? checked
                : typeof value === 'string' && value.length > 1
                  ? value.trimStart()  // Trim leading spaces only if more than one character
                  : value,
            }
            : item
        )
        : {
          ...prevData[category],
          [name]: type === "checkbox"
            ? checked
            : typeof value === 'string' && value.length > 1
              ? value.trimStart()  // Trim leading spaces only if more than one character
              : value,
        },
    }));
  };


  // const handleChange = (index, name, value, type = 'text') => {
  //   setData(prevData => ({
  //     ...prevData,
  //     [name]: prevData[name].map((item, i) =>
  //       i === index ? { ...item, [type]: value } : item
  //     ),
  //   }));
  // };

  const handleCheckboxChange = (e, index, category, name) => {
    const { checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].map((item, i) =>
        i === index ? { ...item, [name]: checked } : item
      ),
    }));
  };

  // const addNewEntry = (type) => {
  //   setData(prevData => ({
  //     ...prevData,
  //     [type]: [...prevData[type], { name: '', age: '', lifeInsurance: false, isNew: true }],
  //   }));

  //   console.log(data.activeIncome,"add - activeIncome")
  // };

  const addNewEntry = (type) => {
    if (!type) {
      console.error("Invalid type provided");
      return;
    }


    let newVal;
    switch (type) {
      case "activeIncome":
        newVal = { name: '', startYear: '', endYear: '', annual: '', incomeReplacement: false };
        break;
      case "homeEquity":
        newVal = { home: '', currentValue: '', mortgageProtection: true };
        break;
      case "commute":
        newVal = { name: '', oneWay: '', annual: '', accidentalDeathBenefit: true };
        break;
      case "fourZeroOneK":
        newVal = { name: '', monthly: '', balance: '', personalPensionPlan: true };
        break;
      case "savings":
        newVal = { bank: '', balance: '', monthly: '', speciallyDesignedWholeLife: true };
        break;
      default:
        console.error("Unsupported type provided");
        return;
    }

    setData((prevData) => ({
      ...prevData,
      [type]: [...(prevData[type] || []), newVal],
    }));
  };


  const deleteEntry = (index, type) => {

    setData(prevData => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Header name="ASSETS / INCOME" />

      <Box sx={{ padding: 2, marginBottom: 6 }}>

        {data.activeIncome.map((income, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('activeIncome')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Income
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Name"
                  value={income.name}
                  onChange={(e) =>
                    handleChange(e, index, 'activeIncome', 'name')
                  }
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Annual"
                  value={income.annual}
                  onChange={(e) =>
                    handleChange(e, index, 'activeIncome', 'annual')
                  }
                />
              </div>

              {/* {index !== 0 && (income.isNew || income.startYear !== undefined || income.endYear !== undefined) && (
                <>
                  <div className="grid-item">
                    <input
                      type="text"
                      className="extrafields"
                      onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                      placeholder="Start Year"
                      value={income.startYear || ''}
                      onChange={(e) =>
                        handleChange(e, index, 'activeIncome', 'startYear')
                      }
                    />
                  </div>

                  <div className="grid-item extrafields">
                    <input
                      type="text"
                      className="extrafields"
                      onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                      placeholder="End Year"
                      value={income.endYear || ''}
                      onChange={(e) =>
                        handleChange(e, index, 'activeIncome', 'endYear')
                      }
                    />
                  </div>
                </>
              )} */}

              {/* {((income.startYear || income.endYear) || index >= 1) && ( */}
                <>
                  <div className="grid-item">
                    <input
                      type="text"
                      onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                      placeholder="End Year"
                      value={income.endYear || ''}
                      onChange={(e) =>
                        handleChange(e, index, 'activeIncome', 'endYear')
                      }
                    />
                  </div>
                </>
              {/* )} */}

              {/* Add Checkbox */}
              <div className={income.isNew || index !== 0 ? 'grid-item checkboxItem' : 'grid-item checkboxItem'}>
                <FormControlLabel sx={{ whiteSpace: 'nowrap' }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={income.incomeReplacement}
                      onChange={(e) =>
                        handleCheckboxChange(e, index, 'activeIncome', 'incomeReplacement')
                      }
                    />

                  }
                  label="Income Replacement"
                />
                {data.activeIncome.length > 1 && (
                  <IconButton
                    color="#EFEFEF"
                    className="iconFix no-hover"
                    onClick={() => deleteEntry(index, 'activeIncome')}
                  >
                    <DeleteIcon />
                  </IconButton>

                )}
              </div>
            </div>
          </Grid>
        ))}


        {/* {data.passiveIncome.map((income, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('passiveIncome')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Passive Income
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <div className="emptyInput"></div>
              </div>

              <div className="grid-item">
              <input
                type="text"
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                placeholder="Monthly"
                value={income.monthly}
                onChange={(e) =>
                  handleChange(e,index, 'passiveIncome', 'monthly')
                }
              />
            </div>

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Annual"
                  value={income.annual}
                  onChange={(e) =>
                    handleChange(e, index, 'passiveIncome', 'annual')
                  }
                />
              </div>

              <div className="grid-item">
                <div className="emptyInput"></div>
              </div>

              <div className="grid-item checkboxItem">
                <FormControlLabel
                  sx={{ whiteSpace: 'nowrap' }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={income.incomeReplacement}
                      onChange={(e) =>
                        handleCheckboxChange(e, index, 'passiveIncome', 'incomeReplacement')
                      }
                    />
                  }
                  label="Income Replacement"
                />
                {data.passiveIncome.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix no-hover" onClick={() => deleteEntry(index, 'passiveIncome')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))} */}


        {/* <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }}>
          <Grid xs={12} md={2} className="responsiveWidth">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" component="span" className="totalAlign">
                Total Household Income
              </Typography>
            </Box>
          </Grid>

          <div className="grid-container">
            <div className="grid-item">
              <div className="emptyInput"></div> 
            </div>

            <div className="grid-item">
              <input
                type="text"
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                placeholder="Annual"
                value={data.totalHouseholdIncome?.annual}
                onChange={(e) =>
                  handleChange(e, 0, 'totalHouseholdIncome', 'annual')
                }
              />
            </div>

            <div className="grid-item">
                <div className="emptyInput"></div>
              </div>

            <div className="grid-item checkboxItem">
              <FormControlLabel sx={{ whiteSpace: 'nowrap' }}
                control={
                  <input
                    type="checkbox"
                    className='checkboxDesign'
                    checked={data.totalHouseholdIncome?.incomeReplacement}
                    onChange={(e) =>
                      handleChange(e, 0, 'totalHouseholdIncome', 'incomeReplacement')
                    }
                  />
                }
                label="Income Replacement"
              />
            </div>
          </div>
        </Grid> */}


        {data.homeEquity.map((equity, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('homeEquity')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Home Equity
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Home"
                  value={equity.home}
                  onChange={(e) =>
                    handleChange(e, index, 'homeEquity', 'home')
                  }
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Current Value"
                  value={equity.currentValue}
                  onChange={(e) =>
                    handleChange(e, index, 'homeEquity', 'currentValue')
                  }
                />
              </div>

              <div className="grid-item">
                <div className="emptyInput"></div> {/* Adjust width as needed */}
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkboxItem">
                <FormControlLabel
                  sx={{ whiteSpace: 'nowrap' }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={equity.mortageProtection}
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'homeEquity', e.target.checked)
                        handleCheckboxChange(e, index, 'homeEquity', 'mortageProtection')

                      }
                    />

                  }
                  label="Mortgage Protection"
                />
                {data.homeEquity.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix no-hover" onClick={() => deleteEntry(index, 'homeEquity')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}

        {data.commute.map((commute, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('commute')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Commute(Miles)
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"

                  placeholder="Name"
                  value={commute.name}
                  onChange={(e) =>
                    handleChange(e, index, 'commute', 'name')
                  }
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  placeholder="One Way"
                  value={commute.oneWay}
                  onChange={(e) =>
                    handleChange(e, index, 'commute', 'oneWay')
                  }
                />
              </div>

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Annual"
                  value={commute.annual}
                  onChange={(e) =>
                    handleChange(e, index, 'commute', 'annual')
                  }
                />
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkboxItem">
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={commute.accidentalDeathBenefit}
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'commute', e.target.checked)
                        handleCheckboxChange(e, index, 'commute', 'accidentalDeathBenefit')
                      }
                    />

                  }
                  label="Accidental Death Benefit"
                />
                {data.commute.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix no-hover" onClick={() => deleteEntry(index, 'commute')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}

        {data.savings.map((saving, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('savings')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  Savings
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Name"
                  value={saving.bank}
                  onChange={(e) =>
                    handleChange(e, index, 'savings', 'bank')
                  }
                />
              </div>

              {/* <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Monthly"
                  value={saving.monthly}
                  onChange={(e) =>
                    handleChange(e, index, 'savings', 'monthly')
                  }
                />
              </div> */}

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Balance"
                  value={saving.balance}
                  onChange={(e) =>
                    handleChange(e, index, 'savings', 'balance')
                  }
                />
              </div>

              <div className="grid-item">
                <div className="emptyInput"></div>
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkboxItem">
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={saving.speciallyDesignedWholeLife}
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'savings', e.target.checked)
                        handleCheckboxChange(e, index, 'savings', 'speciallyDesignedWholeLife')
                      }
                    />

                  }
                  label="Specially Designed Whole Life"
                />
                {data.savings.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix no-hover" onClick={() => deleteEntry(index, 'savings')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}


        {data.fourZeroOneK.map((k401, index) => (
          <Grid container spacing={2} className="gridHeight" alignItems="center" sx={{ mt: 1, flexWrap: 'nowrap' }} key={index}>
            <Grid xs={12} sm={2} className="responsiveWidth">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="primary" sx={{ padding: '1px', color: '#24527B' }} className="no-hover" onClick={() => addNewEntry('fourZeroOneK')}>
                  <AddIcon className="icons" />
                </IconButton>
                <Typography variant="body1" className="spouseTypo">
                  401K
                </Typography>
              </Box>
            </Grid>

            <div className="grid-container">
              <div className="grid-item">
                <input
                  type="text"
                  placeholder="Name"
                  value={k401.name}
                  onChange={(e) =>
                    handleChange(e, index, 'fourZeroOneK', 'name')
                  }
                />
              </div>

              {/* <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Monthly"
                  value={k401.monthly}
                  onChange={(e) =>
                    handleChange(e, index, 'fourZeroOneK', 'monthly')
                  }
                />
              </div> */}

              <div className="grid-item">
                <input
                  type="text"
                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  placeholder="Balance"
                  value={k401.balance}
                  onChange={(e) =>
                    handleChange(e, index, 'fourZeroOneK', 'balance')
                  }
                />
              </div>

              <div className="grid-item">
                <div className="emptyInput"></div>
              </div>

              {/* Add Checkbox */}
              <div className="grid-item checkboxItem">
                <FormControlLabel
                  sx={{ whiteSpace: "nowrap" }}
                  control={
                    <input
                      type="checkbox"
                      className='checkboxDesign'
                      checked={k401.personalPensionPlan}
                      onChange={(e) =>
                        // handleCheckboxChange(index, 'fourZeroOneK', e.target.checked)
                        handleCheckboxChange(e, index, 'fourZeroOneK', 'personalPensionPlan')
                      }
                    />

                  }
                  label="Personal Pension Plan"
                />
                {data.fourZeroOneK.length > 1 && (
                  <IconButton color="#EFEFEF" className="iconFix no-hover" onClick={() => deleteEntry(index, 'fourZeroOneK')}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default AssetsIncome;
